import React from 'react'

function RatingCard({
  rate
}: {
  rate: number | string | undefined,
}) {
  const formattedRate = typeof rate === 'number' ? rate.toFixed(1) : rate;

  return (
    <div className='bg-Red text-[16px] font-medium w-[35px] h-[35px] flex items-center justify-center rounded-[6px] text-White'>
      {formattedRate}
    </div>
  )
}

export default RatingCard