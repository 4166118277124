"use client";
import { returnTokenCookie } from "@/api/actoins/cokkies.api";
import { GetData } from "@/api/actoins/get.api";
import { revalidatePathServer } from "@/api/actoins/revalidatePathServer";
import { toast } from "@/hooks/use-toast";
import { ZINDEX } from "@/utils/config";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { FaHeart } from "react-icons/fa";
function AddToFavouriteSection({
  isFavourite,
  id,
}: {
  isFavourite: boolean;
  id: number;
}) {
  const [customIsFavorite, setCustomIsFavorite] = useState(
    Boolean(isFavourite || false)
  );
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();
  const isAuthorized = true;
  return isAuthorized ? (
    <div
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const token = await returnTokenCookie();
        if (token?.value) {
          setCustomIsFavorite(!customIsFavorite);
          // Prevents event from bubbling up to the parent Link
          const res = await GetData({
            endPoint: `/wishlist/add-remove/${id}`,
          });

          if (!res.success) {
            setCustomIsFavorite(false);
            toast({
              title: t(`${res.message}`),
              variant: "destructive",
            });
          } else {
            await revalidatePathServer("guestPrefer");
            await revalidatePathServer("ourGuestPrefer");
            await revalidatePathServer("youMayLike");
            // router.refresh();
          }
        } else {
          toast({
            title: t("Please login to add to favourites"),
            variant: "destructive",
          });
        }
      }}
      style={{
        zIndex: ZINDEX.likeHeart,
      }}
      className={`absolute sm:top-[15px] top-[10px] end-[15px]   sm:end-[22px] bg-White cursor-pointer rounded-full w-[30px] h-[30px] flex flex-col items-center justify-center mx-auto`}
    >
      <FaHeart className={customIsFavorite ? "text-Red" : "text-Grey"} />
    </div>
  ) : null;
}

export default AddToFavouriteSection;
