'use client'
import * as React from "react"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import PackageCard from "./PackageCard";
import { useLocale } from "next-intl";
import Autoplay from "embla-carousel-autoplay"

function ClientSwiper({ data }: { data: Property[] }) {
  const lang = useLocale()
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: false, stopOnMouseEnter: true })
  )
  return (
    <Carousel
      plugins={[plugin.current]}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={plugin.current.reset}
      // dir={lang === 'ar' ? 'rtl' : 'ltr'}
      opts={{
        align: lang === 'ar' ? 'start' : 'end',
        direction: lang !== 'ar' ? 'rtl' : 'ltr',

        // Add swipe options if available
        // Example: touch: true, // Enable touch swiping
      }}
      className="w-full"
      onEnded={() => plugin.current.play()}
    // Add touch event handlers if necessary
    // onTouchStart={handleTouchStart}
    // onTouchMove={handleTouchMove}
    // onTouchEnd={handleTouchEnd}
    >
      <CarouselContent className="flex flex-row-reverse">
        {data?.map((item, index) => (
          <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/4">
            <PackageCard data={item} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
}

export default ClientSwiper;
