"use client";
import PackageCard from "./PackageCard";
import ClientSwiper from "./ClientSwiper";
import { usePathname } from "next/navigation";
import { useLocale } from "next-intl";
import { cn } from "@/lib/utils";
function PropertiesLayout({
	data,
	isSwiper,
}: {
	data: Property[];
	isSwiper?: boolean;
}) {
	const pathname = usePathname();
	const locale = useLocale();
	const slicedData = pathname.startsWith(`/${locale}/checkout`)
		? data?.slice(0, 5)
		: data;
	return (
		<div className=" w-full overflow-x-hidden">
			{!isSwiper ? (
				<div
					className={cn(
						"grid grid-cols-1 items-center gap-[8px] sm:gap-[16px]",
						{
							"lg:grid-cols-4 grid-cols-2 md:grid-cols-2":
								!pathname.startsWith(`/${locale}/checkout`),
						},
					)}
				>
					{slicedData?.map((item) => (
						<PackageCard
							key={item.id}
							data={item}
						/>
					))}
				</div>
			) : (
				<ClientSwiper data={data} />
			)}
		</div>
	);
}

export default PropertiesLayout;
