"use client";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import AddToFavouriteSection from "../common/AddToFavouriteSection";
import RatingCard from "./RatingCard";
import { charSplit } from "@/utils/config";

function PackageCard({ data }: { data: Property }) {
	const locale = useLocale();
	const t = useTranslations();
	const name =
		locale == "ar"
			? data?.nameAr?.length > charSplit
				? `${data?.nameAr?.substring(0, charSplit)}...`
				: data?.nameAr
			: data?.nameEn?.length > charSplit
				? `${data?.nameEn?.substring(0, charSplit)}...`
				: data?.nameEn;

	const cityAndDistrict =
		locale == "ar"
			? `${data.district.nameAr} ,${data?.City?.nameAr}`
			: `${data.district.nameEn} ,${data?.City?.nameEn}`;
	return (
		//this will be Link to the package details page
		<Link
			href={`/${locale}/details/${data?.id}`}
			className="flex relative z-1 flex-col gap-[14px] cursor-pointer"
		>
			<div className="sm:h-[204px] h-[180px] rounded-[20px] relative w-[100%] overflow-hidden">
				<Image
					src={data?.mainImage}
					objectFit="cover"
					layout="fill"
					alt="package"
					className="rounded-[20px] transform transition-transform duration-300 hover:scale-110"
				/>
			</div>
			<AddToFavouriteSection
				id={data?.id}
				isFavourite={data?.isFavorite || false}
			/>
			<div className=" flex flex-col">
				<div className=" flex items-center justify-between">
					<h1 className=" text-[14px] sm:flex hidden md:text-[16px] text-Black font-bold">
						{name}
					</h1>
					<h1 className=" text-[14px] sm:hidden flex md:text-[16px] text-Black font-bold">
						{name.slice(0, 20)} {name.length > 20 && "..."}
					</h1>
					<RatingCard rate={data?.averageRating} />
				</div>
				<p className="text-DarkGrey text-[9.75px] font-medium">
					{cityAndDistrict}
				</p>
				<div className="font-bold flex items-center gap-1">
					<h1
						style={{
							textDecoration: data?.priceAfterDiscount
								? "line-through"
								: "none",
						}}
						className={
							data?.priceAfterDiscount
								? "text-DarkGrey text-[13px]"
								: "text-Black"
						}
					>
						{" "}
						{data?.price.toFixed(2)} {t("sar")}
					</h1>
					{data?.priceAfterDiscount && (
						<h1>
							{data?.priceAfterDiscount} {t("sar")}
						</h1>
					)}
					<span className="text-DarkGrey text-[9.75px]">
						{t("perNight")}
					</span>
				</div>
			</div>
		</Link>
	);
}

export default PackageCard;
